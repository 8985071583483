import { Component, useState } from "react";
import { DsDynamicForm } from "datasync-dynamic-form";
import { globals } from "../Globals/Globals";


class TimePickerPackage extends Component {
    constructor(props){
        super(props)

        this.time_picker_form = {
            "Rows" : [{//Row-1
              "Cols" : [
                {//col-1
                "Fields" : [
                  {
                    "name" : "hour",
                    "title" : this.props.hour_title,
                    "placeholder" : this.props.hour_placeholder,
                    "combo_list" : ["07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"], 
                    "input_type" : "radio",
                    "min_length" : -1,
                    "max_length" : -1,
                    "required"  : this.props.required
                  }],
                  },
                  {//Col-2
                    "Fields":[
                    {
                        "name" : "minutes",
                        "title" : this.props.minutes_title,
                        "placeholder" : this.props.minutes_placeholder,
                        "combo_list" : ["00", "15", "20", "25", "30", "35", "40", "45", "50", "55"], 
                        "input_type" : "radio",
                        "min_length" : -1,
                        "max_length" : -1,
                        "required"  : this.props.required
                        }
                    ],
                  }],
                },//End of Row-1
            ]}
    }

    render = () => {
        return(
            <DsDynamicForm
                datasync_url = {globals.datasync_service.url}
                form = {this.time_picker_form}
                buttons_options = {{cancel_button_hidden:true, submit_button_hidden:true}}
                //onFormChange = {(timeValuePack)=>{this.setState({time:timeValuePack}); console.log("timeValuePack->", timeValuePack)}}
                onFormChange = {(timeValuePack) => {if (this.props.onChange) this.props.onChange(timeValuePack); console.log("timeValuePack->", timeValuePack)}}
                company_guid = {null}
                table_guid= {null}
                clearOnSave={true}
                clearOnUpdate={true}
                onFormSaved = {()=>{alert("TimePickerPackage OnFormSaved");}}
                onFormFailed = {()=>{alert("TimePickerPackage onFormFailed");}}
          /> 
        )
    }
}

export default TimePickerPackage;